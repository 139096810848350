<template lang="pug">
.c-pfa-advisor-association-form.o-grid.o-grid--direction-row.o-grid--justify-content-center.o-grid--align-items-center.o-grid--gutter-tiny
  .o-grid__group(v-if='!!confirmation')
    .o-grid__item.o-grid__item--12
      c-notification-inline.o-bodytext.o-bodytext--size-small(:canClose='false', :type='confirmation === "success" ? "ok" : "error"' justify='left') {{ confirmation === 'success' ? model.formSavingSuccessMessage : model.formSavingFailureMessage }}
  .o-grid__group(v-if='confirmation != "success"')
    .o-grid__item.o-grid__item--12
      c-fc-text(:model='memberData.memberName' @validate='validate($event, "memberName")' v-model='memberData.memberName.value')
    .o-grid__item.o-grid__item--12
      c-fc-email(:model='memberData.memberEmail' @validate='validate($event, "memberEmail")' v-model='memberData.memberEmail.value')
    .o-grid__item.o-grid__item--12
      c-fc-text(:model='memberData.memberNumber' @validate='validate($event, "memberNumber")' v-model='memberData.memberNumber.value')
    .o-grid__item.o-grid__item--12
      c-fc-select(:model='memberData.memberType' @validate='validate($event, "companyTypeId")' v-model='memberData.memberType.value')
    .o-grid__item.o-grid__item--12
      c-fc-checklist(
        :model='memberData.requirements'
        @validate='validate($event, "requirements")'
        v-if='memberData.requirements.options.length > 0'
        v-model='memberData.requirements.value'
      )
    .o-grid__item.o-grid__item--grow-0
      c-cta.o-button--inverted(:cta-disabled='!canSumbmit', :cta-loading='ctaLoading', :cta-text='model.buttonText' @click='setApplication')
</template>
<script>
import FormControllSelect from '@/components/form/controlls/form-select'
import FormControllText from '@/components/form/controlls/form-text'
import FormControllEmail from '@/components/form/controlls/form-email'
import FormControllChecklist from '@/components/form/controlls/form-checklist'
import { membershipOrganizationsService } from '@/_services'
export default {
  name: 'c-pfa-advisor-association-form',
  data() {
    return {
      validation: {},
      ctaLoading: false,
      confirmation: undefined,
      memberData: {
        memberName: {
          helperText: this.model.nameHelpText,
          placeholderText: this.model.nameQuestion,
          value: null,
          isMandatory: true,
          validation: {
            errorMessage: this.model.nameValidationMessage
          }
        },
        memberEmail: {
          helperText: this.model.emailHelpText,
          placeholderText: this.model.emailQuestion,
          value: null,
          isMandatory: true,
          readOnly: false,
          validation: {
            errorMessage: this.model.emailValidationMessage,
            mask: this.model.emailValidationRule,
            displayRule: '',
            maxLength: 250
          }
        },
        memberNumber: {
          helperText: this.model.memberNumberHelpText,
          placeholderText: this.model.memberNumberQuestion,
          value: null,
          isMandatory: true,
          validation: {
            errorMessage: this.model.memberNumberValidationMessage,
            displayRule: this.model.memberNumberDisplayRule,
            mask: this.model.memberNumberValidationRule
          }
        },
        memberType: {
          helperText: this.model.memberTypeHelpText,
          value: null,
          placeholderText: this.model.memberTypeQuestion,
          readOnly: false,
          isMandatory: true,
          validation: {
            errorMessage: this.model.memberTypeValidationMessage
          },
          options: this.getMemberTypeOptions()
        },
        requirements: {
          helperText: this.model.requirementsHelpText,
          labelText: this.model.requirementsQuestion,
          value: null,
          readOnly: false,
          isMandatory: false,
          options: this.getRequirementsOptions(),
          validation: {
            errorMessage: this.model.requirementsValidationMessage,
            minSelected: this.model.minimumRequirements
          }
        }
      }
    }
  },
  props: {
    model: { type: Object, required: true }
  },
  components: {
    'c-fc-select': FormControllSelect,
    'c-fc-text': FormControllText,
    'c-fc-email': FormControllEmail,
    'c-fc-checklist': FormControllChecklist
  },
  computed: {
    canSumbmit() {
      return !this.ctaLoading && this.formIsValid
    },
    formIsValid() {
      return (
        this.validation &&
        Object.values(this.validation).every(key => {
          return key
        })
      )
    }
  },
  methods: {
    validate(isValid, id) {
      this.$set(this.validation, id, isValid)
    },
    getMemberTypeOptions() {
      const options = this.model.memberTypeOptions.map(option => {
        return { name: option.name, value: option.personTypeKey }
      })

      return options
    },
    getRequirementsOptions() {
      const options = this.model.requirementsOptions.map(option => {
        return { name: option.text, value: option.id }
      })

      return options
    },
    setApplication() {
      const data = {
        Name: this.memberData.memberName.value,
        Email: this.memberData.memberEmail.value,
        MemberNumber: this.memberData.memberNumber.value,
        MemberType: this.memberData.memberType.value
      }
      this.ctaLoading = true
      membershipOrganizationsService
        .setPfaAssociation(this.model.id, data)
        .then(response => {
          if (response && response.status === 200) {
            this.confirmation = 'success'
          }
        })
        .catch(error => {
          this.confirmation = 'error'
          return error
        })

      this.ctaLoading = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
